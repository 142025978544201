body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f9f9f9; /* Daha modern bir açık ton */
  color: #333333; /* Daha yumuşak bir metin rengi */
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  gap: 20px;
}

.contact-info {
  flex: 1;
  max-width: 400px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
}

.contact-info h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #007BFF;
  border-bottom: 2px solid #007BFF;
  padding-bottom: 5px;
}

.contact-info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-info-item {
  margin-bottom: 15px;
  font-size: 1.1rem;
}

.contact-info-item a {
  color: #007BFF; /* Link için profesyonel bir mavi ton */
  text-decoration: none;
  font-weight: 500;
}

.contact-info-item a:hover {
  text-decoration: underline;
}

.form-container {
  flex: 1;
  max-width: 450px;
  background-color: #ffffff; /* Daha yumuşak bir beyaz */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-input, .form-textarea, .form-select {
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #f7f7f7;
  transition: border-color 0.3s ease;
}

.form-input:focus, .form-textarea:focus, .form-select:focus {
  border-color: #007BFF;
  outline: none;
}

.form-checkbox {
  margin-right: 10px;
}

.form-label {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.form-button {
  width: 100%;
  background-color: #007BFF;
  border: none;
  padding: 15px;
  font-size: 1rem;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .contact-container {
      flex-direction: column;
      align-items: center;
      padding: 20px;
  }

  .contact-info, .form-container {
      max-width: 100%;
  }
}
