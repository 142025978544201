.about-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: center;
  }
  
  .about-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .about-container h1 {
    font-size: 2.5em;
    margin-bottom: 200px;
    margin-top: 200px;
    font-family: 'Courier New', Courier, monospace
  }
  
  .about-container h2 {
    font-size: 1.8em;
    margin-top: 20px;
  }
  
  .about-container p {
    font-size: 1.2em;
    line-height: 1.6;
  }
  