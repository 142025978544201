.modern-button {
  background-color:  #BCCAB4; /* Gri arka plan rengi */
  color: white; /* Yazı rengi */
  border: none; /* Kenarlık yok */
  border-radius: 25px; /* Kenarları yuvarlat */
  padding: 15px 30px; /* İç boşluk */
  font-size: 16px; /* Yazı boyutu */
  cursor: pointer; /* İmleci el şeklinde göster */
  transition: background-color 0.3s, transform 0.2s; /* Geçiş efektleri */
}

.modern-button:hover {
  background-color: #b0b0b0; /* Hover durumunda daha koyu gri */
  transform: scale(1.05); /* Hover durumunda butonu büyüt */
}

.modern-button:focus {
  outline: none; /* Fokus durumunda çerçeve yok */
}
