  .card-content h3 {
    font-weight: bold;
    font-size: 1.5em;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Gölgeli vurgulama */
    border-bottom: 2px solid black; /* Alt çizgi ile vurgulama */
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 600px; /* Genişliği artırdık */
    width: 90%; /* Daha büyük alan */
    position: relative;
  }
  
  .popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px; /* Resim ile içerik arasına boşluk ekledik */
  }
  
  .popup-title {
    font-size: 24px; /* Başlık boyutunu artırdık */
    margin: 10px 0;
    font-weight: bold; /* Kalın yap */
  }
  
  .popup-description {
    font-size: 16px; /* Açıklama boyutu */
    margin: 10px 0 20px; /* Açıklama için boşluk */
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .popup-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup-button:hover {
    background-color: #0056b3;
  }
  