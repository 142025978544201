.order-container {
    padding: 20px;
    text-align: center; /* Metni ortala */
  }
  
  .coming-soon {
    margin-top: 20px;
    padding: 20px;
    border: 2px dashed #A8C2A0; /* Kesik çizgi çerçeve */
    border-radius: 10px; /* Kenarları yuvarlat */
    background-color: #fff; /* Arka plan rengi */
    font-weight: bold; /* Kalın yazı */
    color: black; /* Yazı rengi */
  }
  