.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  
  .content {
    position: relative;
    color: white;
    z-index: 1;
  }
  
  .content h1 {
    font-size: 4rem; /* Daha büyük ve belirgin bir logo boyutu */
    font-weight: bold;
    margin-bottom: 1rem; /* Alt yazıyla biraz boşluk bırak */
    padding: 0.5rem 1rem;
    border-radius: 10px; /* Köşeleri yuvarlak yap */
  }
  
  .content p {
    font-size: 1.5rem;
    padding: 0.3rem 0.8rem;
    border-radius: 8px;
  }
  