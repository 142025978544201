.contact-form {
    max-width: 600px; /* Formun maksimum genişliği */
    margin: 40px auto; /* Yatayda otomatik boşluk, dikeyde 20px boşluk */
    padding: 20px; /* İçerik için boşluk */
    border: 1px solid #ccc; /* Kenarlık */
    border-radius: 8px; /* Kenar yuvarlama */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Gölge efekti */
    background-color: #fff; /* Arka plan rengi */
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  input, textarea {
    width: 48%; /* Genişliği %48 yaparak yan yana olmalarını sağlar */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  textarea {
    height: 100px; /* Açıklama alanı için yükseklik belirleme */
    resize: none; /* Kullanıcının boyutlandırmasını engelleme */
    width: 100%; /* Açıklama alanı genişliği */
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007BFF; /* Mavi tonlarında modern bir arka plan */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3; /* Hover rengi */
  }
  