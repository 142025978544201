.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  margin: 0;
  padding: 0;
}

.background{
  background-color: #f5f5f7;
}

.navbar {
  transition: background-color 0.3s ease; /* Arka plan geçişi */
  background-color: transparent; /* Başlangıçta şeffaf */
}

.navbar .nav-link {
  color: white; /* Başlangıçta yazı rengi beyaz */
  transition: color 0.3s ease; /* Yumuşak geçiş */
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030; /* Üstte görünmesini sağlamak için */
}

.scrolled {
  background-color: rgba(255, 255, 255, 0.9); /* Kaydırma sonrası arka plan rengi */
}

.scrolled .nav-link {
  color: black; /* Kaydırma sonrası yazı rengi siyah */
}

.scrolled .nav-link:hover{
  color: black !important;
}

.scrolled .nav-link:focus {
  color: black !important;
}

.nav-link {
  transition: color 0.3s ease;
  color: white; /* Üzerine gelindiğinde yazı rengini beyaz yap */
  background-color: transparent; /* Arka plan rengini kaldır */
/* Yumuşak geçiş efekti için */
}

.nav-link:hover {
  color: white !important; /* Üzerine gelindiğinde yazı rengini beyaz yap */
  background-color: transparent !important; /* Arka plan rengini kaldır */
}

.nav-link:focus {
  color: white !important;
  background-color: transparent !important;
}
.card-with-image {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-content {
  background-color: #f8f8f8;
}

/* Footer genel görünüm */
footer {
  color: white;
}

footer h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.social-icons .btn {
  color: white;
}

.container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-nav {
  display: flex;
  justify-content: center;
  width: calc(100% - 200px);
}

.navbar-nav .nav-item {
  margin: 0 15px;
}

img {
  max-width: 150px;
  flex-shrink: 0; 
}


/* Hamburger İkonunun Çizgilerini Tasarlıyoruz */
.custom-toggler-icon {
  display: block;
  width: 30px;
  height: 3px;
  background-color: white; /* İkonun rengini beyaz yapıyoruz */
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.custom-toggler-icon:before,
.custom-toggler-icon:after {
  content: '';
  display: block;
  width: 30px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.custom-toggler-icon:before {
  top: -8px;
}

.custom-toggler-icon:after {
  bottom: -8px;
}

/* Aktif durumda (açık menü) ikonun dönüşümü */
.navbar-toggler.collapsed .custom-toggler-icon {
  background-color: transparent;
}

.navbar-toggler.collapsed .custom-toggler-icon:before {
  transform: rotate(45deg);
  top: 0;
}

.navbar-toggler.collapsed .custom-toggler-icon:after {
  transform: rotate(-45deg);
  bottom: 0;
}


.custom-toggler-icon-black {
  display: block;
  width: 30px;
  height: 3px;
  background-color: white; /* İkonun rengini beyaz yapıyoruz */
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.custom-toggler-icon-black:before,
.custom-toggler-icon-black:after {
  content: '';
  display: block;
  width: 30px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.custom-toggler-icon-black:before {
  top: -8px;
}

.custom-toggler-icon-black:after {
  bottom: -8px;
}

/* Aktif durumda (açık menü) ikonun dönüşümü */
.navbar-toggler-black.collapsed .custom-toggler-icon-black {
  background-color: transparent;
}

.navbar-toggler-black.collapsed .custom-toggler-icon-black:before {
  transform: rotate(45deg);
  top: 0;
}

.navbar-toggler-black.collapsed .custom-toggler-icon-black:after {
  transform: rotate(-45deg);
  bottom: 0;
}